
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import CartModule from "@/store/modules/cart.module";
const cartModule = getModule(CartModule, store);

@Component
export default class OrderCompleted extends Vue {
  private get mobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  private hideOrderCompleted(): void {
    cartModule.changeOrderCompleted(false);
  }

  private goToOrders(): void {
    this.$router.push({
      name: "user-profile",
      params: { tab: "orders" },
    });
  }
}
