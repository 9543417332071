
import { Component, Vue } from "vue-property-decorator";
import { ProductsCard } from "@/models/entities/products-card.interface";
import { orders } from "@/services/api/orders.service";
import OrderCompleted from "@/views/Cart/OrderCompleted.vue";
import { products as productsService } from "@/services/api/products.service";
import { lightbulb } from "@/icons";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { CLIENT } from "@/helpers/roles";
import AuthModule from "@/store/modules/auth.module";
import CartModule from "@/store/modules/cart.module";
import OverlayModule from "@/store/modules/overlay.module";
import CarouselProduct from "./CarouselProduct.vue";
import SearchModule from "@/store/modules/search.module";
import ProductDetailsModule from "@/store/modules/productdetails.module";
import SnackbarModule from "@/store/modules/snackbar.module";

const authModule = getModule(AuthModule, store);
const cartModule = getModule(CartModule, store);
const overlayModule = getModule(OverlayModule, store);
const searchModule = getModule(SearchModule, store);
const productDetailsModule = getModule(ProductDetailsModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component({
  components: {
    CarouselProduct,
    OrderCompleted,
  },
})
export default class Home extends Vue {
  private search: string = null;
  private timeout: number = null;
  private featuredProducts = [] as ProductsCard[];
  private productsInPromo = [] as ProductsCard[];
  private lightbulb = lightbulb;

  private carousel = 0;
  private cycle = true;

  private isActive = false;
  private show = true;
  private counter = 0;

  private get products(): ProductsCard[] {
    this.counter++;
    return searchModule.productsCard.map((el) => {
      return { ...el };
    });
  }

  private get categoryId(): number {
    return searchModule.category;
  }

  private get orderCompleted(): boolean {
    return cartModule.showOrderCompleted;
  }

  private get desktop(): boolean {
    return this.$vuetify.breakpoint.mdAndUp;
  }

  private created(): void {
    this.loadData();
  }

  private async loadData(): Promise<void> {
    await Promise.all([
      searchModule.retrieveProducts({
        params: { categoryID: searchModule.category, search: searchModule.src },
      }),
      this.getOrder(),
      this.getFeaturedProducts(),
      this.getInPromoProducts(),
    ]);
  }

  private async getInPromoProducts(): Promise<void> {
    overlayModule.showOverlay();
    const p = await productsService.getProductsView(null, null);
    let products = p.data;
    for (let idx = 0; idx < products.length; idx++) {
      let element = products[idx];
      let newPrices = [];
      for (let elIdx = 0; elIdx < element.prices.length; elIdx++) {
        if (element.prices[elIdx].isInPromo)
          newPrices.push(element.prices[elIdx]);
      }
      element.prices = newPrices;
    }
    this.productsInPromo = products.filter(
      (product: ProductsCard) => product.prices.length > 0
    );
  }

  private async getFeaturedProducts(): Promise<void> {
    overlayModule.showOverlay();
    const fp = await productsService.getFeaturedProducts();
    this.featuredProducts = fp.data;
  }

  private async getOrder(): Promise<void> {
    if (authModule.role == CLIENT) {
      overlayModule.showOverlay();
      const r = await orders.getCart();
      cartModule.getCart(r.data);
    }
  }

  private goToDetails(id: number) {
    let productFound = this.products.find(
      (product: ProductsCard) => product.id === id
    );
    if (productFound) {
      productDetailsModule.changeProduct(productFound);
      this.$router.push({ name: "details" });
    } else {
      snackbarModule.showSnackbar({
        message: "Prodotto non trovato, prova a ricaricare la pagina",
        type: "error",
      });
    }
  }

  private async setGlobalSearch(): Promise<void> {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await searchModule.retrieveProducts({
        params: { categoryID: searchModule.category, search: this.search },
      });
      if (this.$route.name !== "home") this.goToHome();
      cartModule.changeOrderCompleted(false);
    }, 500);
  }

  private onFavorite(ref: string): void {
    if (this.$refs[ref])
      (
        this.$refs[ref][0] as Vue & { changeFavorite: () => void }
      ).changeFavorite();
  }

  private goToHome(): void {
    this.$router.push({ name: "home" });
  }
}
