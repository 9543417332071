var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-12"},[_c('v-row',{staticClass:"search-row ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('z-search-bar',{attrs:{"clearable":"","size":"small"},on:{"input":_vm.setGlobalSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.desktop)?_c('div',{staticClass:"search-info"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pt-6 pb-0 pr-0 pl-5",staticStyle:{"line-height":"0px"},attrs:{"cols":"auto"},domProps:{"innerHTML":_vm._s(_vm.lightbulb)}}),_c('v-col',{staticClass:"z-body-bold pt-6 pb-0",attrs:{"cols":"10"}},[_vm._v(" Prova a scrivere \"Pizzoccheri\"! ")])],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"z-body-regular-small py-0 pl-2 pr-6",attrs:{"cols":"10"}},[_vm._v(" Inserendo il nome del piatto che vuoi cucinare, troverai tutti gli ingredienti che ti servono. ")])],1)],1):_vm._e()],1),_c('div',{staticClass:"wooden-texture ma-0"}),(
      !_vm.categoryId && !_vm.search && !_vm.orderCompleted && _vm.featuredProducts.length > 0
    )?_c('v-carousel',{staticClass:"carousel",attrs:{"height":"500px","hide-delimiter-background":"","show-arrows-on-hover":"","cycle":_vm.cycle},model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}},_vm._l((_vm.featuredProducts),function(featured){return _c('v-carousel-item',{key:featured.id,on:{"mouseenter":function($event){_vm.cycle = false},"mouseleave":function($event){_vm.cycle = true}}},[_c('carousel-product',{attrs:{"product":featured},on:{"carousel-click":_vm.goToDetails}})],1)}),1):_vm._e(),_c('ll-categories-select'),(
      !_vm.categoryId && !_vm.search && !_vm.orderCompleted && _vm.productsInPromo.length > 0
    )?[_c('v-row',{staticClass:"ma-0 px-4 py-4"},[_c('v-col',[_c('span',{staticClass:"LL-h4",staticStyle:{"color":"var(--z-on-background-high)"}},[_vm._v(" Prodotti in offerta ")])])],1),_c('v-row',{staticClass:"ma-0 px-4"},_vm._l((_vm.productsInPromo),function(p,idx){return _c('v-col',{key:p.id,staticClass:"pb-6",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-lazy',{key:`${p.id}_${idx}_${_vm.counter}`,attrs:{"options":{
            threshold: 0.2,
          }},model:{value:(p.isActive),callback:function ($$v) {_vm.$set(p, "isActive", $$v)},expression:"p.isActive"}},[_c('ll-product-card',{key:idx,ref:`promo_${p.id}`,refInFor:true,attrs:{"product":p,"isPreview":false},on:{"card-click":_vm.goToDetails,"favorite":function($event){return _vm.onFavorite(`product_${p.id}`)}}})],1)],1)}),1)]:_vm._e(),(_vm.orderCompleted)?_c('order-completed'):_vm._e(),_c('v-row',{staticClass:"ma-0 px-4 py-4"},[(!_vm.categoryId && !_vm.search)?_c('v-col',[_c('span',{staticClass:"LL-h4",staticStyle:{"color":"var(--z-on-background-high)"}},[_vm._v(" Tutti i prodotti ")])]):_vm._e()],1),(_vm.products.length > 0)?_c('v-row',{staticClass:"ma-0 px-4"},_vm._l((_vm.products),function(p,idx){return _c('v-col',{key:p.id,staticClass:"pb-6",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-lazy',{key:`${p.id}_${idx}_${_vm.counter}`,attrs:{"options":{
          threshold: 0.1,
        }},model:{value:(p.isActive),callback:function ($$v) {_vm.$set(p, "isActive", $$v)},expression:"p.isActive"}},[_c('ll-product-card',{key:idx,ref:`product_${p.id}`,refInFor:true,attrs:{"product":p,"isPreview":false},on:{"favorite":function($event){return _vm.onFavorite(`promo_${p.id}`)},"card-click":_vm.goToDetails}})],1)],1)}),1):_c('v-row',{staticClass:"px-10 py-0"},[_c('v-col',{staticClass:"z-body-regular-bold"},[_vm._v("Nessun prodotto trovato")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }