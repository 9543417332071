
import { Component, Prop, Vue } from "vue-property-decorator";
import { productPhoto } from "@/services/api/productPhoto.service";
import { ProductsCard } from "@/models/entities/products-card.interface";

@Component
export default class CarouselProduct extends Vue {
  /** Product prop */
  @Prop({ required: true })
  private product: ProductsCard;

  private carouselPhotoIsLoading = true;
  private photoSrc = "";

  private get imageWidth(): string {
    if (this.mobile) return "100%";
    if (this.$vuetify.breakpoint.sm) {
      return "300px";
    }
    if (this.$vuetify.breakpoint.lgAndUp) {
      return "420px";
    }
    return "360";
  }

  private get cuttedDescription(): string {
    if (this.product.productDescription.length > 512) {
      if (this.$vuetify.breakpoint.smAndDown)
        return `${this.product.productDescription.slice(0, 256)}...`;
      return `${this.product.productDescription.slice(0, 512)}...`;
    }
    return this.product.productDescription;
  }

  private get mobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  private get desktop(): boolean {
    return this.$vuetify.breakpoint.mdAndUp;
  }

  private mounted(): void {
    this.getCarouselPhoto();
  }

  private async getCarouselPhoto(): Promise<void> {
    this.carouselPhotoIsLoading = true;
    this.photoSrc = "";
    const result = await productPhoto.getDefaultPhotoByProductId(
      this.product.id
    );
    this.photoSrc = window.URL.createObjectURL(
      new Blob([new File([result.data], "file")], { type: "image/*" })
    );
    this.carouselPhotoIsLoading = false;
  }
}
